export const JOSAA = "JOSAA";
export const CSAB = "CSAB";

export interface CounsellingOptionConfig {
    name: string;
    sourceLink: string;
    yearsOfData: number[];
}


export const JOSAA_CONFIG: CounsellingOptionConfig = {
    name: "JOSAA",
    sourceLink: "https://josaa.admissions.nic.in/applicant/seatmatrix/openingclosingrankarchieve.aspx",
    yearsOfData: [2024, 2023],
};

export const CSAB_CONFIG: CounsellingOptionConfig = {
    name: "CSAB",
    sourceLink: "https://admissions.nic.in/csabspl/Applicant/SeatAllotmentResult/CurrentORCR.aspx",
    yearsOfData: [2023],
}

export const DEFAULT_CONFIG = JOSAA_CONFIG;