import { PaginationState, SortingState } from "@tanstack/react-table";
import { CustomFilterData } from "../../common/components/filter/CustomFilter";
import { useQuery } from "@tanstack/react-query";
import { FetchJosaaDataResponse } from "../types/response";
import { addFilter, addPagination, addSort } from "../../common/utils";
import config from "../../config";

const useGetJeeData = (
    counsellingOption: number,
    isCounsellingOptionsLoading: boolean,
    year: number,
    round: number,
    pagination: PaginationState,
    filters: CustomFilterData,
    sorting: SortingState
): [
    FetchJosaaDataResponse | undefined,
    boolean
] => {
    const fetchJosaaData = async (): Promise<FetchJosaaDataResponse> => {
        try {
            let url = `${config.apiUrl}/jee/josaa`;
            url = `${url}?round=${round}&year=${year}&`
            url = addFilter(url, filters);
            url = addSort(url, sorting);
            url = addPagination(url, pagination);
            // const username = "nitin";
            // const password = "nitin";
            const headers = new Headers();

            // headers.set("Authorization", `Basic ${btoa(`${username}:${password}`)}`);
            headers.set("Content-Type", "application/json");
            const response = await fetch(url, {method: 'GET', headers});
            if (!response.ok) {
              return {
                rows: [],
                pageCount: 0,
                rowCount: 0,
              }
            }
            const data = await response.json();
            return {
                rows: data.rows,
                rowCount: data.rowCount,
                pageCount: data.pageCount,
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    const fetchCsabData = async (): Promise<FetchJosaaDataResponse> => {
      try {
          let url = `${config.apiUrl}/jee/csab`;
          url = `${url}?round=${round}&year=${year}&`
          url = addFilter(url, filters);
          url = addSort(url, sorting);
          url = addPagination(url, pagination);
          // const username = "nitin";
          // const password = "nitin";
          const headers = new Headers();

          // headers.set("Authorization", `Basic ${btoa(`${username}:${password}`)}`);
          headers.set("Content-Type", "application/json");
          const response = await fetch(url, {method: 'GET', headers});
          if (!response.ok) {
            return {
              rows: [],
              pageCount: 0,
              rowCount: 0,
            }
          }
          const data = await response.json();
          return {
              rows: data.rows,
              rowCount: data.rowCount,
              pageCount: data.pageCount,
          }
      } catch (err) {
          return Promise.reject(err);
      }
  }

    const {data, isFetching, isLoading} = useQuery({
        queryKey: [
          'jee-data',
          counsellingOption,
          year,
          round,
          pagination,
          filters,
          sorting,
        ],
        enabled: !isCounsellingOptionsLoading,
        queryFn: counsellingOption === 1 ? fetchJosaaData : fetchCsabData,
        staleTime: Infinity,
          // placeholderData: keepPreviousData,
      });

    return [data, isLoading && isFetching];
}

export default useGetJeeData;