import { Box } from '@mui/material'
import React, { PropsWithChildren, ReactNode } from 'react'

interface Props extends PropsWithChildren {
  filterIcon?: ReactNode;
}
const CustomTableHeader: React.FC<Props> = ({children, filterIcon}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '80%' }}>
          {children}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '20%', justifyContent: 'end' }}>
          {filterIcon && filterIcon}
      </Box>
    </Box>
  )
}

export default CustomTableHeader;