import { useContext } from "react";
import { JeeContext } from "../context/jee";

const useSelectedCounsellingOption = () => {
    const {counselling} = useContext(JeeContext);

    return {
        name: counselling.options.find(option => option.id === counselling.selectedId)?.name ?? "",
        id: counselling.selectedId,
        option: counselling.options.find(option => option.id === counselling.selectedId)?.name.toUpperCase() ?? "",
    }
}

export default useSelectedCounsellingOption;