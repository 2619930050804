import CustomPageHeader from "../common/components/pageHeader/CustomPageHeader";
import JeeTableHeader from "./JEETableHeader";
import { useState } from "react";
import JeeTable from "./JEETable";
import JeeFilter from "./JEEFilter";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { CustomFilterData } from "../common/components/filter/CustomFilter";
import useGetJeeData from "./query/useGetJeeData";
import useGetCounsellingOptions from "./query/useGetCounsellingOptions";
import { JeeContext } from "./context/jee";
import config from "../config";

// TODO: Change everything based on counselling options selected
const JeePage = () => {
  const [isOpen, setOpen] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<CustomFilterData>({});
  const [counsellingOptions, isCounsellingOptionsLoading] = useGetCounsellingOptions();
  const [year, setYear] = useState(config.defaultYear);
  const [counsellingOption, setCounsellingOption] = useState(1);
  const [round, setRound] = useState(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, isLoading] = useGetJeeData(counsellingOption, isCounsellingOptionsLoading, year, round, pagination, filters, sorting);

  return (
    <JeeContext.Provider value={
      {
        counselling: {
          options: counsellingOptions?.rows ?? [],
          isLoading: isCounsellingOptionsLoading,
          selectedId: counsellingOption,
          year: year,
        }
      }
    }>
      <CustomPageHeader title="Join Entrance Examination (JEE), 2025" />
      <JeeTableHeader
        year={year}
        setYear={setYear}
        round={round}
        setRound={setRound}
        counsellingOption={counsellingOption}
        setCounsellingOption={setCounsellingOption}
        openFilterDialog={() => setOpen(true)}
      />
      <JeeFilter
        isOpen={isOpen}
        close={() => setOpen(false)}
        filters={filters}
        setFilters={setFilters}
      />
      <JeeTable
        data={data}
        isLoading={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
      />
    </JeeContext.Provider>
  )
}

export default JeePage;